<template>
    <b-container fluid>
      <b-card>
        <span v-if="errorMessage" class="text-danger"> {{ errorMessage }}</span>
        <validation-observer ref="inquiryRules">
            <b-form @submit.prevent="onSubmit">
                <b-row>
                <b-col md="3">
                    <b-form-group label="Inquiry Date *">
                        <validation-provider
                            #default="{ errors }"
                            name="Inquiry Date"
                            rules="required"
                        >
                            <!-- <flat-pickr 
                                v-model="form.inquiry_date" 
                                class="form-control" 
                                :config="{dateFormat: 'Y-m-d'}"
                                placeholder="Enter Inquiry date">
                            
                            </flat-pickr> -->
                            <b-form-input 
                                id="inquiry-date"
                                v-model="form.inquiry_date"
                                placeholder="Enter Inquiry date"
                                name="sdate"
                                type="date">
                            </b-form-input>
                            <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                    </b-form-group>
                </b-col>  
                <b-col md="3">
                    <b-form-group label="Requirement Date *">
                        <validation-provider
                            #default="{ errors }"
                            name="Requirement Date"
                            rules="required"
                        >
                            <!-- <flat-pickr 
                                v-model="form.requirement_date" 
                                class="form-control" 
                                :config="{dateFormat: 'Y-m-d'}"
                                placeholder="Enter Requirement date">
                            
                            </flat-pickr> -->
                            <b-form-input 
                                id="requirement-date"
                                v-model="form.requirement_date"
                                placeholder="Enter Requirement date"
                                name="sdate"
                                type="date">
                            </b-form-input>
                            <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                    </b-form-group>
                </b-col>
                <b-col md="6">  
                    <b-form-group label="Project Locations *">
                        <validation-provider
                            #default="{ errors }"
                            name="Project Locations"
                            rules="required"
                        >
                            <b-form-input v-model="form.project_location" placeholder="Enter Project Locations" id="project-locations" type="text"></b-form-input>
                            <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                    </b-form-group>
                </b-col>  
                <b-col md="3">  
                    <b-form-group label="Client *">
                        <validation-provider
                            #default="{ errors }"
                            name="Client Name"
                            rules="required"
                        >
                            <v-select 
                                id="client-name"
                                v-model="form.client_name" 
                                :options="clients"
                                label="title"
                                :reduce="(option) => option.title"
                                placeholder="Select Client"
                                push-tags
                                taggable
                                />
                            
                            <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>    
                    </b-form-group>
                </b-col>  
                
        
                <b-col md="3">
                    <b-form-group label="Business Sector *">
                        <validation-provider
                            #default="{ errors }"
                            name="Business Sector"
                            rules="required"
                        >
                            <v-select 
                                id="business-sector"
                                v-model="form.business_sector" 
                                :options="sectors"
                                label="title"
                                :reduce="(option) => option.title"
                                placeholder="Select Business Sector"
                                push-tags
                                taggable/>
                            <small class="text-danger">{{ errors[0] }}</small>                                
                        </validation-provider>
                    </b-form-group>
                </b-col>  
                <b-col md="3">  
                    <b-form-group label="Contractual Model *">
                        <validation-provider
                            #default="{ errors }"
                            name="Contractual Model"
                            rules="required"
                        >                        
                            <v-select 
                                id="contractual-model"
                                v-model="form.contractual_model" 
                                :options="models"
                                label="title"
                                :reduce="(option) => option.title"
                                placeholder="Select Commercial Model"/>
                            <small class="text-danger">{{ errors[0] }}</small>                                
                        </validation-provider>    
                    </b-form-group>
                </b-col>
                <b-col md="3">  
                    <b-form-group label="Status *">
                        <validation-provider
                            #default="{ errors }"
                            name="Inquiry Status"
                            rules="required"
                        >
                            <v-select 
                            id="inquiry-status"
                                v-model="form.status"
                                :options="statusOptions"
                                label="title"
                                :reduce="(option) => option.title"
                                placeholder="Select Inquiry Status"
                            />
                            <small class="text-danger">{{ errors[0] }}</small>                                
                        </validation-provider>    
                    </b-form-group>
                </b-col>
            </b-row>
            <div class="mb-2 mt-2 text-center font-weight-bold">

                <u><b-link   @click="addPosition">  {{ addPositiontext }}</b-link></u>

            </div>
            
            <div v-if="form && form.infra_position_inquiries">
                <b-table
                    striped
                    hover
                    responsive
                    :items="form.infra_position_inquiries"
                    :fields="positionFields"
                    style="width: 100% !important"
                >
                <template #cell(actions)="data" >
                    <div class="text-nowrap" @click="editPosition(data.item.id)" v-b-tooltip.hover.bottom="'Edit Position'">
                        <feather-icon size="18" icon="EditIcon" class="text-info"/>
                    </div>
                    <div class="text-nowrap" @click="removePosition(data.item.id)" v-b-tooltip.hover.bottom="'Remove Position'">
                        <feather-icon size="18" icon="XIcon" class="text-danger"/>
                    </div>
                </template>
                </b-table>
            </div>

            
            <b-row v-if="addNewPosition">

                <b-col md="3">    
                    <b-form-group label="Position/Requirement *">
                    <!-- <b-form-input 
                        v-model="position.position"
                        placeholder="Enter Position/Requirement">
                    </b-form-input>
                    
                     -->
                     <validation-provider
                            #default="{ errors }"
                            name="position"
                            rules="required"
                        >
                        <v-select 
                            id="position"
                            v-model="position.position"
                            :options="positionList"
                            label="title"
                            :reduce="(option) => option.title"
                            taggable
                            push-tags
                            placeholder="Select Position/Requirement"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                     </validation-provider>       
                    </b-form-group>
                </b-col>  
                <b-col md="3">    
                    <b-form-group label="Quantum">
                    <b-form-input type="number" v-model="position.quantum"></b-form-input>
                    </b-form-group>
                </b-col>
        
                <b-col md="3">
                    <b-form-group label="Min Experience">
                    <b-form-input v-model="position.min_experience" placeholder="in years"></b-form-input>
                    </b-form-group>
                </b-col>
                <b-col md="3">
                    <b-form-group label="Max Experience">
                    <b-form-input v-model="position.max_experience" placeholder="in years"></b-form-input>
                    </b-form-group>
                </b-col>  
                <b-col md="3">
                    <b-form-group label="Qualification">
                    <b-form-input v-model="position.qualification"></b-form-input>
                    </b-form-group>
                </b-col>  
                <b-col md="3">  
                    <b-form-group label="Status">
                        <v-select 
                                v-model="position.position_status"
                                :options="statusOptions"
                                label="title"
                                :reduce="(option) => option.title"
                                placeholder="Select Inquiry Status"
                            />
                    </b-form-group>
                </b-col>  
                <b-col md="3">  
                    <b-form-group label="TAT Deputation">
                    <!-- <b-form-input v-model="position.tat_deputation"></b-form-input> -->
                        <v-select 
                            v-model="position.tat_deputation"
                            :options="tatDeputations"
                            label="title"
                            :reduce="(option) => option.title"
                            placeholder="Select Tat Deputation"
                            />
                    </b-form-group>
                </b-col>
        
                <b-col md="3">
                    <b-form-group label="Project Duration">
                    <!-- <b-form-input v-model="position.project_duration"></b-form-input> -->
                        <v-select 
                            v-model="position.project_duration"
                            :options="projectDurations"
                            label="title"
                            :reduce="(option) => option.title"
                            placeholder="Select Project Duration"
                            />
                    </b-form-group>
                </b-col>  
                <b-col md="3">  
                    <b-form-group label="Salary Survey Current CTC">
                    <b-form-input type="number" v-model="position.salary_survey_curr_ctc"></b-form-input>
                    </b-form-group>
                </b-col>  
                <b-col md="3">  
                    <b-form-group label="Salary Survey Expected CTC">
                    <b-form-input type="number" v-model="position.salary_survey_exp_ctc"></b-form-input>
                    </b-form-group>
                </b-col>          
                <b-col md="6">
                    <b-form-group label="Job Description">
                    <b-form-textarea v-model="position.job_description" rows="3"></b-form-textarea>
                    </b-form-group>
                </b-col>
                <b-col md="3">  
                    <b-form-group label="HR Client">
                    <b-form-input v-model="position.hr_client"></b-form-input>
                    </b-form-group>
                </b-col>
                <b-col md="3">  
                    <b-form-group label="HR Contact Mobile">
                    <b-form-input v-model="position.hr_contact_mobile"></b-form-input>
                    </b-form-group>
                </b-col>
                <b-col md="3">  
                    <b-form-group label="HR Contact Office">
                    <b-form-input v-model="position.hr_contact_office"></b-form-input>
                    </b-form-group>
                </b-col>
        
                <b-col md="3">
                    <b-form-group label="SPOC Client">
                    <b-form-input v-model="position.spoc_client"></b-form-input>
                    </b-form-group>
                </b-col>  
                <b-col md="3">  
                    <b-form-group label="SPOC Contact Mobile">
                    <b-form-input v-model="position.spoc_contact_mobile"></b-form-input>
                    </b-form-group>
                </b-col>  
                <b-col md="3">  
                    <b-form-group label="SPOC Contact Office">
                    <b-form-input v-model="position.spoc_contact_office"></b-form-input>
                    </b-form-group>
                </b-col>  
                <b-col md="3">  
                    <b-form-group label="SPOC Email">
                    <b-form-input v-model="position.spoc_email"></b-form-input>
                    </b-form-group>
                </b-col>  
                <b-col md="3">  
                    <b-form-group label="Working Days">
                    <b-form-input v-model="position.working_days"></b-form-input>
                    </b-form-group>
                </b-col>
                <b-col md="6">
                    
                    <b-button type="submit-position" variant="primary" @click="submitPosition">Add Position</b-button>
                </b-col>

            </b-row>
            <b-row class="mt-1 text-center">    
                <b-col md="12">
                    <u><b-link v-if="form.infra_position_inquiries"  @click="postionsDone"> Done Adding Positions? </b-link></u>
                    <b-button class="ml-2" type="submit" :disabled="submitDisabled" variant="primary">Submit Inquiry</b-button>
                </b-col>
            </b-row>
            </b-form>
        </validation-observer>
      </b-card>
    </b-container>
  </template>
  
  <script>

  /*
   - create spacing between submit inquiry and submit position
   - disable submit inquiry if no requirement specified
   - give option to edit or remove positions
   - All positions need to be send along with inquiry as json array
   - when inquiry gets created use position list and call postion Controller to add all positions in iteration add inquiry id
  */
  import {
    BForm, BContainer, BRow, BCol, BButton, BFormGroup, BFormInput, BFormSelect, BFormTextarea, BCard, BLink, BTable, VBTooltip
  } from 'bootstrap-vue'

  import flatPickr from 'vue-flatpickr-component'
  import { ValidationProvider, ValidationObserver } from "vee-validate";
  import vSelect from 'vue-select';
  import { required,double } from '@validations'
  import 'vue-select/dist/vue-select.css';
  import AdminConfigService from '@/services/admin-config-service'
  import InfraInquiryService from '@/services/infra-inquiry-service'
  import InfraPositionInquiryService from '@/services/infra-position-inquiry-service'
  
  import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
  export default {
    components: {
      flatPickr,
      vSelect,
      BForm, BContainer, BRow, BCol, BButton, BFormGroup, BFormInput, BFormSelect, BFormTextarea, BCard,BLink, BTable,
      ValidationProvider,
      ValidationObserver,
    },
    data() {
      return {
        form: {
          inquiry_id: null,
          inquiry_date: null,
          requirement_date: null,
          client_name: null,
          project_location: null,
          business_sector: null,
          contractual_model: null,
          added_by: null,
          updated_by: null,
          status: null,
          infra_position_inquirie: [],
        },
        position: {
          //position_id: null,
          position: '',
          quantum: null,
          experience: '',
          min_experience: '',
          max_experience: '',
          qualification: '',
          position_status: '',
          educational_specification: '',
          job_description: '',
          tat_deputation: '',
          project_duration: '',
          salary_survey_curr_ctc: null,
          salary_survey_exp_ctc: null,
          hr_client: '',
          hr_contact_mobile: '',
          hr_contact_office: '',
          spoc_client: '',
          spoc_contact_mobile: '',
          spoc_contact_office: '',
          spoc_email: '',
          working_days: '',
          added_by: '',
          updated_by: '',
        },
        //positionList: [],
        clients: [], // Assume this will be filled by an API call
        statusOptions: [],
        // locations: [], // Assume this will be filled by an API call
        sectors: [], // Assume this will be filled by an API call
        models: [],
        positionFields: [
            { key: "actions", label: 'EDIT' },
            { key: 'position', label: 'Position Name', sortable: true  },
            { key: 'quantum', label: 'quantum', sortable: true  },
            { key: 'experience', label: 'Pexperience', sortable: true  },
            { key: 'qualification', label: 'qualification', sortable: true  },
            { key: 'position_status', label: 'status', sortable: true  },
            { key: 'job_description', label: 'job description', sortable: true  },
            { key: 'tat_deputation', label: 'tat deputation', sortable: true  },
            { key: 'project_duration', label: 'project duration', sortable: true  },
            { key: 'salary_survey_curr_ctc', label: 'salary survey curr ctc', sortable: true  },
            { key: 'salary_survey_exp_ctc', label: 'salary survey exp ctc', sortable: true  },
            { key: 'hr_client', label: 'hr client', sortable: true  },
            { key: 'hr_contact_mobile', label: 'hr contact mobile', sortable: true  },
            { key: 'hr_contact_office', label: 'hr contact office', sortable: true  },
            { key: 'spoc_client', label: 'hr client', sortable: true  },
            { key: 'spoc_contact_mobile', label: 'spoc contact mobile', sortable: true  },
            { key: 'spoc_contact_office', label: 'spoc contact office', sortable: true  },
            { key: 'spoc_email', label: 'spoc email', sortable: true  },
            { key: 'working_days', label: 'working days', sortable: true  },
        ],
        addNewPosition: false,
        addPositiontext: 'Add New Position/Requirement', // Assume this will be filled by an API call
        submitDisabled: true,
        errorMessage: null,
        tatDeputations: [],
        projectDurations: [],
        positions: [],
        inquiry_id: null,
        
      };
    },
    directives: {
        "b-tooltip": VBTooltip
    },
    watch: {
        form: {
        handler(newFilters) {
          // Convert query parameters and new filters to strings for easy comparison
          const currentQuery = JSON.stringify(this.$route.query);
          const newQuery = JSON.stringify(newFilters);

          // Only navigate if the queries are different
          if (currentQuery !== newQuery) {
            this.$router.push({ query: newFilters }).catch(err => {
              if (err.name !== 'NavigationDuplicated') {
                throw err;
              }
              // Ignore NavigationDuplicated error, but rethrow all other errors
            });
          }
        },
        deep: true,
      },
      $route(to) {
        this.form = { ...this.form, ...to.query };
      },
    },
    created() {
        
        // Fetch questions and user stats on component creation
        const userData = JSON.parse(localStorage.getItem('userData'));
        this.empId = userData.emp_id
        this.role = userData.roles[0].toLowerCase()
        this.name = userData.first_name + ' ' + userData.last_name
        this.form.added_by = {
            title: this.name,
            empId: this.empId,
            email: userData.email
        }
        this.form.updated_by = {
            title: this.name,
            empId: this.empId,
            email: userData.email
        }
    },
    methods: {
        getInquiry() {
            InfraInquiryService.getInquiry(encodeURIComponent(this.inquiry_id)).then(res => {
            console.log('inquiry data:', res)
            //this.InfraInquiryList = res.data  

            if(res.status === 200) {
                this.form = res.data

                //this.form.inquiry_date = ''
            } else {

            }
        })
        },
        validationForm() {
            this.$refs.inquiryRules.validate().then(success => {
                if (success) {
                // eslint-disable-next-line
                    this.validatedForm = true
                } else {
                    alert('Form validation Failed !!!')
                }
            })
        },
        onSubmit() {
            this.errorMessage = null
            console.log(this.form);
            
            const data = {
                inquiry_date: this.form.inquiry_date,
                requirement_date: this.form.requirement_date,
                client_name: this.form.client_name,
                project_location: this.form.project_location,
                business_sector: this.form.business_sector,
                commercial_model: this.form.commercial_model,
                updated_by: this.form.updated_by,
                status: this.form.status

            }
            //if(this.validatedForm) {
                InfraInquiryService.updateInquiry(this.inquiry_id,data).then((res) => {
                    console.log(res)
                    if(res.status === 200) {
                        this.$toast({
                            component: ToastificationContent,
                            props: {
                            title: res.data.message,
                            icon: "EditIcon",
                            variant: "success",
                            },
                        });
                        this.$router.push({ name: "infra-inquiry" });
                    } else {
                        this.errorMessage = res.data
                    }
                })
            //}
        },
        addPosition() {
            this.addNewPosition = !this.addNewPosition;
        },
        postionsDone() {
            this.submitDisabled = false
        },
        submitPosition() {
            this.errorMessage = null
            if(this.position.min_experience || this.position.max_experience) {
                this.position.experience = this.position.min_experience + '-' + this.position.max_experience
            }
            
            this.position.inquiry_id = this.inquiry_id
            console.log('Position Data: ', this.position)
            const userData = JSON.parse(localStorage.getItem('userData'));
            this.empId = userData.emp_id
            this.role = userData.roles[0].toLowerCase()
            this.name = userData.first_name + ' ' + userData.last_name
            if(!this.position_id) {
                this.position.added_by = {
                    title: this.name,
                    empId: this.empId,
                    email: userData.email
                }
            }
            
            this.position.updated_by = {
                title: this.name,
                empId: this.empId,
                email: userData.email
            }

            InfraPositionInquiryService.createOrUpdateInquiry(this.position).then(res => {
                console.log(res)
                if(res.status === 201) {
                    this.getInquiry()
                    this.$toast({
                        component: ToastificationContent,
                        props: {
                            title: res.data.message,
                            icon: "EditIcon",
                            variant: "success",
                        },
                    });

                } else {
                    this.errorMessage = res.data
                }
            })
            // this.postion.id = Date.now()
            // this.position.added_by = this.form.added_by
            // this.position.updated_by = this.form.added_by
            // if(!this.position.id) {
            //     const pos = {
            //         ...this.position,
            //         id: Date.now(),
            //         added_by: this.form.added_by,
            //         updated_by: this.form.added_by
            //     }
            //     console.log('Position Data pos: ', pos)

            //     this.form.infra_position_inquiries.push(pos)
            // } else {
            //     this.form.infra_position_inquiries = this.form.infra_position_inquiries.filter(item => item.id !== this.position.id);
            //     this.form.infra_position_inquiries.push(this.position)
            // }
            
            //this.form.positions.push(pos)
            //this.positionList.push(this.position)
            this.position = {
                id: null,
                position: '',
                quantum: null,
                experience: '',
                min_experience: '',
                max_experience: '',
                qualification: '',
                position_status: '',
                educational_specification: '',
                job_description: '',
                tat_deputation: '',
                project_duration: '',
                salary_survey_curr_ctc: null,
                salary_survey_exp_ctc: null,
                hr_client: '',
                hr_contact_mobile: '',
                hr_contact_office: '',
                spoc_client: '',
                spoc_contact_mobile: '',
                spoc_contact_office: '',
                spoc_email: '',
                working_days: ''
            }
            console.log('positionList: ',this.form.infra_position_inquiries)
            this.addNewPosition = false
        },
        editPosition(id) {
            this.addNewPosition = true
            this.position = this.form.infra_position_inquiries.filter(item => item.id === id)[0]
            console.log('position detail:', this.position)
            this.position.inquiry_id = this.inquiry_id            
        },
        removePosition(id) {
            this.position = this.form.infra_position_inquiries.filter(item => item.id === id)[0]
            this.form.infra_position_inquiries = this.form.infra_position_inquiries.filter(item => item.id !== id)
            const data = {
                position_status: 'CANCELLED'
            }
            InfraPositionInquiryService.updatePositionInquiry(this.position.inquiry_id, this.position.position_id, data).then(res => {
                console.log(res)
                if(res.status === 200) {
                    this.getInquiry()
                    this.$toast({
                            component: ToastificationContent,
                            props: {
                            title: res.data.message,
                            icon: "EditIcon",
                            variant: "success",
                            },
                        });
                } else {
                    this.errorMessage = res.data
                }
            })
        }
    },
    updated() {
        if(this.$route.query) {
          //this.searchFilters = { ...this.searchFilters, ...this.$route.query };
            this.form.inquiry_date = this.$route.query.inquiry_date ? this.$route.query.inquiry_date : ''
            this.form.requirement_date = this.$route.query.requirement_date ? this.$route.query.requirement_date : ''
            this.form.client_name = this.$route.query.client_name ? this.$route.query.client_name : ''
            this.form.project_location = this.$route.query.project_location ? this.$route.query.project_location : ''
            this.form.business_sector = this.$route.query.business_sector ? this.$route.query.business_sector : ''
            this.form.contractual_model = this.$route.query.contractual_model ? this.$route.query.contractual_model : ''
            // this.position.position = this.$route.query.position ? this.$route.query.position : ''
            // this.position.quantum = this.$route.query.quantum ? this.$route.query.quantum : ''
            // this.position.experience = this.$route.query.experience ? this.$route.query.experience : ''
            // this.position.qualification = this.$route.query.qualification ? this.$route.query.qualification : ''
            // this.form.status = this.$route.query.status ? this.$route.query.status : ''
            // this.form.educational_specification = this.$route.query.educational_specification ? this.$route.query.educational_specification : ''
            // this.form.job_description = this.$route.query.job_description ? this.$route.query.job_description : ''
            // this.form.tat_deputation = this.$route.query.tat_deputation ? this.$route.query.tat_deputation : ''
            // this.form.project_duration = this.$route.query.project_duration ? this.$route.query.project_duration : ''
            // this.form.salary_survey_curr_ctc = this.$route.query.salary_survey_curr_ctc ? this.$route.query.salary_survey_curr_ctc : ''
            // this.form.salary_survey_exp_ctc = this.$route.query.salary_survey_exp_ctc ? this.$route.query.salary_survey_exp_ctc : ''
            // this.form.hr_client = this.$route.query.hr_client ? this.$route.query.hr_client : ''
            // this.form.hr_contact_mobile = this.$route.query.hr_contact_mobile ? this.$route.query.hr_contact_mobile : ''
            // this.form.hr_contact_office = this.$route.query.hr_contact_office ? this.$route.query.hr_contact_office : ''
            // this.form.spoc_client = this.$route.query.spoc_client ? this.$route.query.spoc_client : ''
            // this.form.spoc_contact_mobile = this.$route.query.spoc_contact_mobile ? this.$route.query.spoc_contact_mobile : ''
            // this.form.spoc_contact_office = this.$route.query.spoc_contact_office ? this.$route.query.spoc_contact_office : ''
            // this.form.spoc_email = this.$route.query.spoc_email ? this.$route.query.spoc_email : ''
            // this.form.working_days = this.$route.query.working_days ? this.$route.query.working_days : ''
        } 
    },
    mounted() {
        this.inquiry_id = this.$route.params.id

        this.getInquiry();

        AdminConfigService.getConfigInfra().then(res => {
            if (res.status === 'OK'){
                console.log('infra config list: ', res.data)
                this.clients = res.data.clients;
                this.models = res.data.models;
                this.statusOptions = res.data.statusOptions;
                this.sectors = res.data.sectors
                this.tatDeputations = res.data.tatDeputations
                this.projectDurations = res.data.projectDurations
                this.positionList = res.data.positionList
            }
        })
    }
  }
  </script>
  
  <style scoped lang="scss">
  /* Add styles here if needed */
  @import "@core/scss/vue/libs/vue-select.scss";
  @import '@core/scss/vue/libs/vue-flatpicker.scss';
  </style>
  